import Link from 'next/link'
import OriginPrice from './OriginPrice'
import SalePrice from './SalePrice'

interface PriceType {
  type?: string
  classes?: string
  classesSale?: string
  link?: string
  priceAtWarehouse?: boolean
  hasGift?: boolean
  originPrice: number
  finalPrice: number
}

export const Price = ({
  type,
  classes,
  classesSale,
  link = '',
  // priceAtWarehouse,
  // hasGift,
  originPrice,
  finalPrice,
}: PriceType) => {
  return (
    <Link
      href={link}
      className={`price-wrapper mt-1 w-full ${type ? type : ''}`}
    >
      <div className='sale-price flex items-center justify-between'>
        <SalePrice finalPrice={finalPrice} classes={classesSale} type={type} />
      </div>
      <OriginPrice originPrice={originPrice} classes={classes} type={type} />
    </Link>
  )
}

export default Price
